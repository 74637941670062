import Alpine from 'alpinejs'
import htmx, { on } from 'htmx.org'

import lazy from './js/lazy.js'
import animations from './js/animations.js'
import ras from './js/ras.js'
import Grid from './js/grid.js';

window.Alpine = Alpine
window.htmx = htmx
Alpine.start()

ras.init();
lazy.init();
animations.init();
initGrid();

function htmxBefore(evt) {
}

function htmxAfter(evt) {
  lazy.init();
  animations.init();

  const swappedElement = evt.detail.elt;

  if (window.Alpine) {
    Alpine.initTree(swappedElement);
  }


  if (evt.target.classList.contains('js-htmx-main')) {
    initGrid();
    setTimeout(() => {

    }, 500);
    window.scrollTo(0, 0);
  }
}

function initGrid() {
  // Advanced usage with custom settings
  const customGrid = new Grid({
    rootSelector: '.js-grid',
    renderSelector: '.js-grid-display',
    itemSelector: 'li',
    columns: 4,
    gap: '1rem',
    breakpoints: [
      { width: 992, columns: 2, gap: '20px' },
      { width: 576, columns: 1, gap: '15px' }
    ],
    onReady: function(grid) {
      console.log('Grid initialized:', grid);
      // Process HTMX and Alpine on initial load
      if (window.htmx) {
        htmx.process(grid.renderContainer);
      }
      if (window.Alpine) {
        Alpine.initTree(grid.renderContainer);
      }
      lazy.init();
    },
    onResizeStop: function(grid) {
      console.log('Grid resized stop:', grid);
      // Re-process HTMX and Alpine after resize
      if (window.htmx) {
        htmx.process(grid.renderContainer);
      }
      if (window.Alpine) {
        Alpine.initTree(grid.renderContainer);
      }
      lazy.init();
    },
  });
}

htmx.on("htmx:beforeSend", function(evt) {
  console.log('htmx:beforeSend');
  htmxBefore(evt);
});

htmx.on("htmx:historyRestore", function(evt) {
  console.log('htmx:historyRestore');
  htmxAfter(evt);
});

htmx.on("htmx:afterSwap", function(evt) {
  console.log('htmx:afterSwap');
  htmxAfter(evt);
});

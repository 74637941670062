class Grid {
  constructor(options = {}) {
    // Default settings
    this.settings = {
      rootSelector: '.js-grid',
      renderSelector: '.js-grid-render',
      itemSelector: 'li',
      columns: 4,
      gap: '1.5rem',
      breakpoints: [
        { width: 768, columns: 2, gap: '1rem' },
        { width: 480, columns: 1, gap: '0.75rem' }
      ],
      resizeDelay: 250, // Delay in ms before considering resize "stopped"
      // Callbacks
      onInit: null,      // Called when grid is initialized
      onReady: null,     // Called when grid is fully rendered
      onResize: null,    // Called during resize
      onResizeStop: null, // Called when resize has stopped
      onDistribute: null, // Called when items are distributed
      ...options
    };

    // Initialize resize timeout reference
    this.resizeTimeout = null;

    // Track current rendered items
    this.currentItems = [];

    this.init();
  }

  init() {
    // Call onInit callback if provided
    if (typeof this.settings.onInit === 'function') {
      this.settings.onInit(this);
    }

    // Get the grid container
    this.container = document.querySelector(this.settings.rootSelector);
    if (!this.container) return;

    // Grab all items from the container
    this.items = Array.from(this.container.querySelectorAll(this.settings.itemSelector));
    if (this.items.length === 0) return;

    // Make the original container sr-only
    this.makeSrOnly(this.container);

    this.createRenderContainer();
    this.createColumns();
    this.distributeItems();
    this.setupResponsive();

    // Call onReady callback if provided
    if (typeof this.settings.onReady === 'function') {
      this.settings.onReady(this);
    }
  }

  // Add sr-only class to make content accessible only to screen readers
  makeSrOnly(element) {
    element.classList.add('sr-only');
    // Add common sr-only styles inline as a fallback
    element.style.position = 'absolute';
    element.style.width = '1px';
    element.style.height = '1px';
    element.style.padding = '0';
    element.style.margin = '-1px';
    element.style.overflow = 'hidden';
    element.style.clip = 'rect(0, 0, 0, 0)';
    element.style.whiteSpace = 'nowrap';
    element.style.borderWidth = '0';
  }

  createRenderContainer() {
    // Create the render container with grid layout
    this.renderContainer = document.createElement('div');
    this.renderContainer.className = this.settings.renderSelector.replace('.', '');
    this.renderContainer.setAttribute('aria-hidden', 'true');
    this.applyGridStyles(this.settings.columns, this.settings.gap);

    // Insert the render container after the original grid
    this.container.parentNode.insertBefore(this.renderContainer, this.container.nextSibling);
  }

  applyGridStyles(columns, gap) {
    this.renderContainer.style.display = 'grid';
    this.renderContainer.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
    this.renderContainer.style.gap = gap; // Gap for the container columns
  }

  createColumns() {
    // Create columns (ul elements)
    this.columns = Array.from({ length: this.settings.columns }, () => {
      const ul = document.createElement('ul');
      ul.style.listStyle = 'none';
      ul.style.padding = '0';
      ul.style.margin = '0';

      // Apply flex layout for better height behavior
      ul.style.display = 'flex';
      ul.style.flexDirection = 'column';
      ul.style.gap = this.settings.gap; // Same gap as the parent

      return ul;
    });

    // Add columns to the render container
    this.columns.forEach(column => {
      this.renderContainer.appendChild(column);
    });
  }

  distributeItems() {
    // Clear current items tracking array
    this.currentItems = [];

    // Distribute items across the columns
    this.items.forEach((item, index) => {
      const columnIndex = index % this.settings.columns;
      const clonedItem = item.cloneNode(true);
      clonedItem.style.height = 'fit-content';
      this.columns[columnIndex].appendChild(clonedItem);

      // Track the current rendered items
      this.currentItems.push({
        originalIndex: index,
        columnIndex: columnIndex,
        element: clonedItem
      });
    });

    // Call onDistribute callback if provided
    if (typeof this.settings.onDistribute === 'function') {
      this.settings.onDistribute(this, this.columns);
    }
  }

  setupResponsive() {
    if (!this.settings.breakpoints || this.settings.breakpoints.length === 0) return;

    // Create and add a style element for media queries
    const style = document.createElement('style');
    document.head.appendChild(style);

    let cssRules = '';

    // Create media queries for each breakpoint
    this.settings.breakpoints.forEach(breakpoint => {
      cssRules += `
        @media (max-width: ${breakpoint.width}px) {
          ${this.settings.renderSelector} {
            grid-template-columns: repeat(${breakpoint.columns}, 1fr) !important;
            gap: ${breakpoint.gap} !important;
          }

          ${this.settings.renderSelector} > ul {
            gap: ${breakpoint.gap} !important;
          }
        }
      `;
    });

    style.textContent = cssRules;

    // Bind handlers once to avoid memory leaks
    this.boundResizeHandler = this.handleResize.bind(this);
    this.boundDebouncedResizeHandler = this.handleResizeStop.bind(this);

    // Add resize handler to adjust layout during resize
    window.addEventListener('resize', this.boundResizeHandler);

    // Add debounced resize handler for resize stop events
    window.addEventListener('resize', () => {
      // Clear the previous timeout
      clearTimeout(this.resizeTimeout);

      // Set a new timeout
      this.resizeTimeout = setTimeout(this.boundDebouncedResizeHandler, this.settings.resizeDelay);
    });
  }

  handleResize() {
    // Get current layout configuration based on window width
    const currentConfig = this.getLayoutConfig();

    if (currentConfig) {
      // Store references to the current rendered items before clearing
      const previousItems = [...this.currentItems];
      this.currentItems = [];

      // Clear existing columns
      while (this.renderContainer.firstChild) {
        this.renderContainer.removeChild(this.renderContainer.firstChild);
      }

      // Update the container's grid
      this.applyGridStyles(currentConfig.columns, currentConfig.gap);

      // Create new columns based on current config
      this.columns = Array.from({ length: currentConfig.columns }, () => {
        const ul = document.createElement('ul');
        ul.style.listStyle = 'none';
        ul.style.padding = '0';
        ul.style.margin = '0';

        // Apply flex layout for column items
        ul.style.display = 'flex';
        ul.style.flexDirection = 'column';
        ul.style.gap = currentConfig.gap;

        return ul;
      });

      // Add columns to the render container
      this.columns.forEach(column => {
        this.renderContainer.appendChild(column);
      });

      // Redistribute items - using previous rendered items if available
      this.items.forEach((originalItem, index) => {
        const columnIndex = index % currentConfig.columns;

        // Find the previous item if it exists
        const prevItem = previousItems.find(item => item.originalIndex === index);

        // Clone from previous item if it exists, otherwise from original
        const sourceItem = prevItem ? prevItem.element : originalItem;
        const clonedItem = sourceItem.cloneNode(true);
        clonedItem.style.height = 'fit-content';

        this.columns[columnIndex].appendChild(clonedItem);

        // Track the current rendered items
        this.currentItems.push({
          originalIndex: index,
          columnIndex: columnIndex,
          element: clonedItem
        });
      });

      // Call onResize callback if provided
      if (typeof this.settings.onResize === 'function') {
        this.settings.onResize(this, currentConfig);
      }

      // Call onDistribute callback if provided
      if (typeof this.settings.onDistribute === 'function') {
        this.settings.onDistribute(this, this.columns);
      }
    }
  }

  // Handler for resize stop event
  handleResizeStop() {
    // Get current layout configuration
    const currentConfig = this.getLayoutConfig();

    // Call onResizeStop callback if provided
    if (typeof this.settings.onResizeStop === 'function') {
      this.settings.onResizeStop(this, currentConfig);
    }
  }

  // Fixed method to get the appropriate layout configuration based on window width
  getLayoutConfig() {
    const windowWidth = window.innerWidth;
    const breakpoints = [...this.settings.breakpoints].sort((a, b) => b.width - a.width);

    // Find the first breakpoint that applies (largest breakpoint that's smaller than window width)
    for (let i = 0; i < breakpoints.length; i++) {
      if (windowWidth <= breakpoints[i].width) {
        return breakpoints[i];
      }
    }

    // If no breakpoint applies, return the default settings
    return {
      columns: this.settings.columns,
      gap: this.settings.gap
    };
  }

  // Public method to manually trigger a resize
  refresh() {
    this.handleResize();

    // Trigger resize stop event as well
    clearTimeout(this.resizeTimeout);
    this.handleResizeStop();
  }

  // Public method to update settings and rebuild the grid
  update(newSettings = {}) {
    // Update settings
    this.settings = {
      ...this.settings,
      ...newSettings
    };

    // Clear existing grid
    if (this.renderContainer) {
      this.renderContainer.remove();
    }

    // Reset current items tracking
    this.currentItems = [];

    // Rebuild grid
    this.init();
  }

  // Public method to destroy the grid and clean up
  destroy() {
    // Remove event listeners
    window.removeEventListener('resize', this.boundResizeHandler);

    // Clear any pending timeouts
    clearTimeout(this.resizeTimeout);

    // Remove the render container
    if (this.renderContainer) {
      this.renderContainer.remove();
    }

    // Restore original container visibility
    if (this.container) {
      this.container.classList.remove('sr-only');
      this.container.style = '';
    }

    // Clear current items tracking
    this.currentItems = [];
  }
}

export default Grid;